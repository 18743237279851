import React from 'react';
import {Store} from 'rc-field-form/lib/interface';
import {Form, Button, Input, Select, Divider} from 'shared/ui-kit/antd';
import {IOrderForm, TValues} from '../../types';
import {getFields, getOrderFormFields, prepareValues} from '../../helpers';
import {clearError, getError} from 'shared/lib/helpers/form';
import {deliveryStatusOptions, statusOptions, transactionStatusOptions} from 'shared/consts/order';
import {Typography} from 'antd';
const {Text} = Typography;

const deviderStyle = {borderTop: '1px solid white', display: 'flex', width: '100%', margin: '30px 0px'};

export const OrderForm: React.FC<IOrderForm> = ({onSubmit, values, errors = {}, clearErrors}) => {
  const [form] = Form.useForm();
  const fields = values && getFields(values);
  const {address, delivery_status, status, region, city, state, suite, transaction_status, nfcTags} =
    getOrderFormFields();
  const onFinish = (values: TValues) => {
    const {data, nfc} = prepareValues(values);
    onSubmit && onSubmit(data, nfc);
  };
  return (
    <Form
      form={form}
      onFinish={onFinish as (values: Store) => void}
      labelCol={{
        xs: {span: 24},
        sm: {span: 8},
      }}
      wrapperCol={{
        xs: {span: 24},
        sm: {span: 8},
      }}
      fields={fields}>
      <Form.Item
        label={region.label}
        name={region.name}
        rules={region.rules}
        validateStatus={getError(region.name, errors).validateStatus}
        help={getError(region.name, errors).help}>
        <Input placeholder={region.label} onChange={clearError(region.name, errors, clearErrors)} disabled />
      </Form.Item>
      <Form.Item
        label={state.label}
        name={state.name}
        rules={state.rules}
        validateStatus={getError(state.name, errors).validateStatus}
        help={getError(state.name, errors).help}>
        <Input placeholder={state.label} onChange={clearError(state.name, errors, clearErrors)} />
      </Form.Item>
      <Form.Item
        label={city.label}
        name={city.name}
        rules={city.rules}
        validateStatus={getError(city.name, errors).validateStatus}
        help={getError(city.name, errors).help}>
        <Input placeholder={city.label} onChange={clearError(city.name, errors, clearErrors)} />
      </Form.Item>
      <Form.Item
        label={address.label}
        name={address.name}
        rules={address.rules}
        validateStatus={getError(address.name, errors).validateStatus}
        help={getError(address.name, errors).help}>
        <Input placeholder={address.label} onChange={clearError(address.name, errors, clearErrors)} />
      </Form.Item>
      <Form.Item
        label={suite.label}
        name={suite.name}
        rules={suite.rules}
        validateStatus={getError(suite.name, errors).validateStatus}
        help={getError(suite.name, errors).help}>
        <Input placeholder={suite.label} onChange={clearError(suite.name, errors, clearErrors)} />
      </Form.Item>
      <Form.Item
        label={status.label}
        name={status.name}
        rules={status.rules}
        validateStatus={getError(status.name, errors).validateStatus}
        help={getError(status.name, errors).help}>
        <Select onChange={clearError(status.name, errors, clearErrors)} options={statusOptions} />
      </Form.Item>
      <Form.Item
        label={delivery_status.label}
        name={delivery_status.name}
        rules={delivery_status.rules}
        validateStatus={getError(delivery_status.name, errors).validateStatus}
        help={getError(delivery_status.name, errors).help}>
        <Select onChange={clearError(delivery_status.name, errors, clearErrors)} options={deliveryStatusOptions} />
      </Form.Item>
      <Form.Item
        label={transaction_status.label}
        name={transaction_status.name}
        rules={transaction_status.rules}
        validateStatus={getError(transaction_status.name, errors).validateStatus}
        help={getError(transaction_status.name, errors).help}>
        <Select
          onChange={clearError(transaction_status.name, errors, clearErrors)}
          options={transactionStatusOptions}
          disabled
        />
      </Form.Item>
      <Divider style={deviderStyle} />
      <Text>List tags separated by commas</Text>
      <Form.Item
        label={nfcTags.label}
        name={nfcTags.name}
        rules={nfcTags.rules}
        validateStatus={getError(nfcTags.name, errors).validateStatus}
        help={getError(nfcTags.name, errors).help}>
        <Input placeholder={nfcTags.label} onChange={clearError(nfcTags.name, errors, clearErrors)} />
      </Form.Item>
      <Form.Item
        wrapperCol={{
          xs: {span: 24, offset: 0},
          sm: {span: 16, offset: 8},
        }}>
        <Button type="primary" htmlType="submit">
          Update
        </Button>
      </Form.Item>
    </Form>
  );
};
