import {useState} from 'react';
import {ExecutionResult} from 'graphql';
import {useNavigate, useParams} from 'react-router-dom';
import {useMutation, useQuery} from '@apollo/react-hooks';
import {message} from 'shared/ui-kit/antd';
import {CreateUser, EditUserQuery} from '../api/queries';
import {CreateUserResponse} from '../types';
import {GetUserById} from 'entities/User';
import {GetRolesByIdUser} from 'entities/Roles';
import {generateRandomString} from 'shared/lib/helpers/string';
import {GetUserData, TUser} from 'shared/types/user';
import {getErrorObject} from 'shared/lib/helpers/error';
import {TRoleItem} from 'shared/types/role';
import {routes} from 'shared/consts/route';

export const useCreateUser = () => {
  const [createHandler, {loading}] = useMutation<CreateUserResponse>(CreateUser);
  const [errors, setErrors] = useState({});
  const [values, setValues] = useState<TUser>({
    password: generateRandomString(),
    roles: ['admin'],
  });
  const navigate = useNavigate();

  const createUserHandler = async (values: TUser) => {
    try {
      setValues(values);
      const {data}: ExecutionResult<CreateUserResponse> = await createHandler({
        variables: {...values},
      });
      if (!data) message.error("Server hasn't sent data in response !");

      data?.adminCreateUser
        ? message.success('User has been successfully created!')
        : message.error("User hasn't been successfully created!");

      data?.adminCreateUser && navigate(routes.usersView.get({id: data.adminCreateUser.user.id || ''}));
    } catch (e) {
      const error = getErrorObject(e);
      let errorObject = {};
      error.message.includes('username') && (errorObject = {username: [error.message]});
      error.message.includes('email') && (errorObject = {...errorObject, email: [error.message]});
      setErrors({...errorObject});
    }
  };

  const clearErrors = (name: string) => {
    setErrors({...errors, [name]: []});
  };
  return {
    values,
    errors,
    loading,
    clearErrors,
    createUserHandler,
  };
};

export const useEditUser = () => {
  const {id} = useParams<{id: string}>();
  const navigate = useNavigate();
  const {loading, error: errorGetUser, data} = useQuery<GetUserData>(GetUserById, {variables: {id}});
  const {data: rolesUser, error: errorGetRolesUser} = useQuery(GetRolesByIdUser, {variables: {id}});
  const [values, setValues] = useState<TUser>({});
  const [errors, setErrors] = useState({});
  const [callEditUser, {loading: callEditUserLoading}] = useMutation<TUser>(EditUserQuery);

  const editUserHandler = async (values: TUser) => {
    setValues(values);
    try {
      const {data}: ExecutionResult = await callEditUser({
        variables: {...values, id: id},
      });

      data?.adminUpdateUser
        ? message.success('User has been successfully updated!')
        : message.error("User hasn't been successfully updated!");

      navigate(routes.usersView.get({id: id || ''}));
    } catch (e) {
      const error = getErrorObject(e);
      let errorObject = {};
      error.message.includes('username') && (errorObject = {username: [error.message]});
      error.message.includes('email') && (errorObject = {...errorObject, email: [error.message]});

      setErrors({...errorObject});
    }
  };

  function clearErrors(name: string) {
    setErrors({...errors, [name]: []});
  }

  errorGetUser && message.error(errorGetUser.message);
  errorGetRolesUser && message.error(errorGetRolesUser.message);

  if (!values?.username && data?.user && rolesUser?.roles) {
    setValues({
      ...data.user,
      roles: rolesUser?.roles?.edges.map((item: TRoleItem) => item.node.name),
    });
  }
  const val = values?.username ? values : data?.user || {};
  return {
    id,
    user: data?.user,
    values: val,
    errors,
    loading: loading || callEditUserLoading,
    clearErrors,
    editUserHandler,
  };
};
