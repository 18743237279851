import React from 'react';
import {Typography} from 'antd';
import moment from 'moment';

const {Text} = Typography;

interface Props {
  children?: string;
}
export const DateTime: React.FC<Props> = ({children}) => (
  <Text>{children ? moment(children).format('YYYY-MM-D HH:mm') : '-'}</Text>
);
