import React from 'react';
import {PageHeader} from 'shared/ui-kit/PageHeader';
import {routes} from '../../helpers';
import {useCreateUser} from '../../hooks';
import {UserForm} from '../UserForm';

export const CreateUserForm: React.FC = () => {
  const {errors, values, loading, clearErrors, createUserHandler} = useCreateUser();
  return (
    <PageHeader title="Add User" loading={loading} routes={routes}>
      <UserForm onSubmit={createUserHandler} errors={errors} clearErrors={clearErrors} values={values} />
    </PageHeader>
  );
};
