import {useNavigate, useParams} from 'react-router-dom';
import {useMutation, useQuery} from '@apollo/react-hooks';
import {message} from 'shared/ui-kit/antd';
import {GetUserData} from '../types/view';
import {BlockUserRequest, DeleteUserRequest, GetUserById} from 'entities/User/api';
import {GetRolesByIdUser} from 'entities/Roles';
import {routes} from 'shared/consts/route';
import {getErrorObject} from 'shared/lib/helpers/error';
import {StatusTypes} from 'shared/consts/user';
import {TRoleItem} from 'shared/types/role';

export const useViewUser = () => {
  const {id} = useParams<{id: string}>();
  const navigate = useNavigate();
  const {loading, error: errorGetUser, data, refetch} = useQuery<GetUserData>(GetUserById, {variables: {id}});
  const {data: rolesUser, error: errorGetRolesUser} = useQuery(GetRolesByIdUser, {variables: {id}});

  const [deleteHandler] = useMutation(DeleteUserRequest);
  const [blockHandler] = useMutation(BlockUserRequest);

  const onDelete = async () => {
    try {
      await deleteHandler({
        variables: {id},
      });
      navigate(routes.users.path);
      message.success(`User ${data?.user.username} has been delete successful!`);
    } catch (e) {
      const error = getErrorObject(e);
      message.error(error.message);
    }
  };
  const onBlock = async () => {
    try {
      await blockHandler({
        variables: {
          id,
          status: data?.user.status === StatusTypes.block ? StatusTypes.active : StatusTypes.block,
        },
      });
      message.success(
        `User ${data?.user.username} has been ${
          data?.user.status === StatusTypes.block ? StatusTypes.unblock : StatusTypes.block
        } successful!`,
      );
      await refetch();
    } catch (e) {
      const error = getErrorObject(e);
      message.error(error.message);
    }
  };

  errorGetUser && message.error(errorGetUser.message);
  errorGetRolesUser && message.error(errorGetRolesUser.message);
  const item = data?.user &&
    rolesUser && {
      ...data.user,
      roles: rolesUser?.roles?.edges.map((item: TRoleItem) => item.node.name),
    };

  return {loading, onBlock, onDelete, id, item, refetch};
};
