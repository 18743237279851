import {TUser} from 'shared/types/user';
import {TValues} from '../types';

export const routes = [
  {
    path: 'users',
    breadcrumbName: 'Users',
  },
  {
    path: ``,
    breadcrumbName: `Add`,
  },
];

export const prepareValues = (values: TValues): TUser => {
  const data: TUser = {
    username: values.username,
    email: values.email,
    roles: values.roles,
  };
  if (values.password) {
    data.password = values.password;
  }
  return data;
};

export const getFields = (values: TUser) => {
  return [
    {
      name: ['username'],
      value: values?.username || '',
    },
    {
      name: ['email'],
      value: values?.email || '',
    },
    {
      name: ['password'],
      value: values?.password || '',
    },
    {
      name: ['roles'],
      value: values?.roles || null,
    },
  ];
};

export const getUserFormFields = (id?: string) => {
  const required = !id;
  return {
    username: {
      label: 'Username',
      name: 'username',
      rules: [
        {
          required: true,
          message: 'Please input User Name!',
        },
      ],
    },
    email: {
      label: 'Email',
      name: 'email',
      type: 'email',
      rules: [
        {
          required: true,
          message: "Please input User's email!",
        },
      ],
    },
    password: {
      label: 'Password',
      name: 'password',
      rules: [
        {
          required,
          message: "Please input User's password!",
        },
      ],
    },
    rolesField: {
      label: 'Roles',
      name: 'roles',
      placeholder: 'Please select roles',
      rules: [
        {
          required,
          message: "Please input User's roles!",
        },
      ],
    },
  };
};

export const getRoutesEdit = (id?: string, username?: string) => {
  return [
    {
      path: 'users',
      breadcrumbName: 'Users',
    },
    {
      path: id || '',
      breadcrumbName: username || id || '',
    },
    {
      path: 'edit',
      breadcrumbName: 'Edit',
    },
  ];
};
