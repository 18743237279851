import {useParams} from 'react-router-dom';
import {useQuery} from '@apollo/react-hooks';
import {message} from 'shared/ui-kit/antd';
import {GetOrderData} from '../types/view';
import {GetOrderById} from 'entities/Order';

export const useViewOrder = () => {
  const {id} = useParams<{id: string}>();
  const {loading, error: errorGetOrder, data, refetch} = useQuery<GetOrderData>(GetOrderById, {variables: {id}});

  errorGetOrder && message.error(errorGetOrder.message);
  return {loading, id, item: data?.order, refetch};
};
