import {FolderOpenOutlined, GroupOutlined, LogoutOutlined, TeamOutlined} from 'shared/ui-kit/icons';
import {TMenuItem} from '../types/menu';
import {routes} from 'shared/consts/route';

export const menuItems: Array<TMenuItem> = [
  {
    key: 'users',
    to: routes.users.path,
    Icon: TeamOutlined,
    title: 'Users',
    match: {path: routes.users.path + '*'},
  },
  {
    key: 'orders',
    to: routes.orders.path,
    Icon: FolderOpenOutlined,
    title: 'Orders',
    match: {path: routes.orders.path + '*'},
  },
  // {
  //   key: 'roles',
  //   to: routes.roles.path,
  //   Icon: SolutionOutlined,
  //   title: 'Roles',
  //   match: {path: routes.roles.path + '*'},
  // },
  {
    key: 'sessions',
    to: routes.sessions.path,
    Icon: GroupOutlined,
    title: 'Sessions',
    match: {path: routes.sessions.path + '*'},
  },
  {
    key: 'logout',
    Icon: LogoutOutlined,
    title: 'Logout',
    to: '',
  },
];
