import React, {PropsWithChildren} from 'react';
import {ApolloProvider, DefaultOptions, InMemoryCache, ApolloClient, createHttpLink} from '@apollo/client';
import {APPLICATION_ID, GRAPHQL_URI, API_KEY} from '../consts/env';
import {setContext} from '@apollo/client/link/context';

const httpLink = createHttpLink({
  uri: GRAPHQL_URI,
});

const authLink = setContext(() => {
  const token = localStorage.getItem('token');
  const headers = {
    'X-Parse-Application-Id': APPLICATION_ID,
    'X-Parse-REST-API-Key': API_KEY,
  };
  if (token) {
    return {
      headers: {
        ...headers,
        'X-Parse-Session-Token': token,
      },
    };
  }
  return {
    headers,
  };
});

const defaultOptions: DefaultOptions = {
  watchQuery: {
    fetchPolicy: 'network-only',
  },
  query: {
    fetchPolicy: 'network-only',
  },
  mutate: {
    fetchPolicy: 'network-only',
  },
};

const client = new ApolloClient({
  link: authLink.concat(httpLink),
  cache: new InMemoryCache(),
  defaultOptions,
});

const Provider: React.FC<PropsWithChildren> = ({children}) => (
  <ApolloProvider client={client}>{children}</ApolloProvider>
);

export default Provider;
