import React from 'react';
import {PlusOutlined} from '@ant-design/icons';
import {DefaultButton} from './Default';

interface Props {
  to?: string;
}

export const CreateButton: React.FC<Props> = ({to, ...rest}) => {
  return (
    <DefaultButton to={to} type="primary" icon={<PlusOutlined />} {...rest}>
      Add
    </DefaultButton>
  );
};
