import styled from 'styled-components';
import {Typography} from 'shared/ui-kit/antd';

export const Username = styled(Typography.Title).attrs({
  level: 4,
})`
  margin: 0 0 0 16px !important;
  color: #fff !important;
`;
export const SidebarTitle = styled.div`
  display: flex;
  justify-content: start;
  padding: 0 24px;
  align-items: center;
  color: #fff !important;
  text-align: center;
  height: 64px;
`;
