import {DocumentNode} from 'graphql';
import {useQuery, OperationVariables} from '@apollo/react-hooks';

export const useImperativeQuery = (query: DocumentNode) => {
  const {refetch} = useQuery(query, {skip: true});

  const imperativelyCallQuery = (variables: OperationVariables = {}) => {
    return refetch(variables);
  };

  return imperativelyCallQuery;
};
