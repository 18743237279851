import React from 'react';
import {Spin as AntSpin} from 'antd';

import {Wrap} from './styles';

const Spin: React.FC = () => (
  <Wrap>
    <AntSpin size="large" tip="Loading..." />
  </Wrap>
);

export default Spin;
