import React from 'react';
import {Tag} from 'shared/ui-kit/antd';
import {SessionsList, useSessions} from 'entities/Session';
import {PageHeader} from 'shared/ui-kit/PageHeader';

export const SessionsListWidget: React.FC = () => {
  const {total, items, loading, fetchData} = useSessions();

  return (
    <PageHeader title="Sessions" tags={<Tag color="#108ee9">{total}</Tag>} loading={loading}>
      <SessionsList items={items} fetchData={fetchData} total={total} />
    </PageHeader>
  );
};
