import React from 'react';
import {BrowserRouter as Router} from 'react-router-dom';
import ApolloProvider from './providers/withApollo';
import {Routes} from 'pages';

export function App() {
  return (
    <ApolloProvider>
      <Router>
        <Routes />
      </Router>
    </ApolloProvider>
  );
}
