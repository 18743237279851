import React from 'react';
import {Select} from 'shared/ui-kit/Select';
import {useGetRoles} from '../../hooks';

interface IRolesInput {
  placeholder: string;
  mode: 'multiple' | 'tags';
  onChange: (value: string[]) => void;
  defaultValue: string[];
}

export const RolesInput: React.FC<IRolesInput> = ({placeholder, mode, onChange, defaultValue}) => {
  const {roles} = useGetRoles();
  return <Select defaultValue={defaultValue} onChange={onChange} roles={roles} placeholder={placeholder} mode={mode} />;
};
