import {gql} from '@apollo/client';

export const GetSessionsByIdUser = gql`
  query getSessions($id: ID, $first: Int, $cursor: String) {
    searchSessions(where: {user: {have: {id: {equalTo: $id}}}}, first: $first, after: $cursor, order: createdAt_ASC) {
      edges {
        cursor
        node {
          id
          objectId
          user {
            id
            username
          }
          sessionToken
          createdWith
          installationId
          createdAt
          updatedAt
          expiresAt
        }
      }
      count
    }
  }
`;
