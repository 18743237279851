export enum StatusTypes {
  new = 'new',
  active = 'active',
  block = 'block',
  unblock = 'unblock',
}

export enum StatusActions {
  Unblock = 'Unblock',
  Block = 'block',
}
