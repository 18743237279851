import {create} from 'zustand';
import {ISession} from 'shared/types/user';
import {IContextValue} from 'shared/types/store';

export const useSessionStore = create<IContextValue>((set) => ({
  session: undefined,
  loading: false,
  setSession: (session: ISession) => {
    set((state) => ({...state, session}));
  },
  clearSession: () => {
    set((state) => ({...state, session: undefined}));
  },
  toggleLoading: () => {
    set((state) => ({...state, loading: !state.loading}));
  },
}));
