import React, {ReactNode} from 'react';
import styled from 'styled-components';
import {Avatar, List} from 'antd';
import {RightOutlined} from '@ant-design/icons';

interface Props {
  title: string;
  icon: ReactNode;
  children: string | ReactNode;
  actions?: ReactNode[];
}

const Item = styled(List.Item)`
  &:hover {
    background: @item-hover-bg;
  }
`;
const RightIcon = styled(RightOutlined)`
  color: @normal-color;
`;

export const ListItem: React.FC<Props> = ({icon, title, actions = [<RightIcon key="1" />], children}) => (
  <Item actions={actions}>
    <List.Item.Meta title={title} avatar={<Avatar icon={icon} />} description={children} />
  </Item>
);
