import {useState} from 'react';
import {useNavigate, useParams} from 'react-router-dom';
import {useMutation, useQuery} from '@apollo/react-hooks';
import {message} from 'shared/ui-kit/antd';
import {getErrorObject} from 'shared/lib/helpers/error';
import {routes} from 'shared/consts/route';
import {GetOrderData, TOrder} from 'shared/types/order';
import {EditHighlightQuery, EditOrderQuery} from '../api/queries';
import {GetOrderById} from 'entities/Order';

export const useEditOrder = () => {
  const {id} = useParams<{id: string}>();
  const navigate = useNavigate();
  const {loading, error: errorGetOrder, data} = useQuery<GetOrderData>(GetOrderById, {variables: {id}});
  const [callEditOrder, {loading: callEditOrderLoading}] = useMutation<TOrder>(EditOrderQuery);
  const [callEditHighlight, {loading: callEditHighlightLoading}] = useMutation(EditHighlightQuery);

  const [values, setValues] = useState<TOrder>({});
  const [errors, setErrors] = useState({});

  const editOrderHandler = async (values: TOrder, nfcTags: string[]) => {
    setValues(values);
    try {
      await callEditOrder({
        variables: {fields: {...values}, id: id},
      });
      await callEditHighlight({
        variables: {
          id: data?.order?.Highlight?.objectId,
          fields: {
            NFCtags: nfcTags,
          },
        },
      });

      navigate(routes.ordersView.get({id: id || ''}));
    } catch (e) {
      const error = getErrorObject(e);
      const errorObject = {internal: [error.message]};
      setErrors({...errorObject});
      message.error(error.message);
    }
  };

  function clearErrors(name: string) {
    setErrors({...errors, [name]: []});
  }

  errorGetOrder && message.error(errorGetOrder.message);
  const val = values?.objectId ? values : data?.order || {};
  return {
    id: id,
    order: data?.order,
    values: val,
    errors,
    loading: loading || callEditOrderLoading || callEditHighlightLoading,
    clearErrors,
    editOrderHandler,
  };
};
