import React, {DetailedHTMLProps, HTMLAttributes} from 'react';
import {Link} from 'react-router-dom';
import {DateTime} from 'shared/ui-kit/DateTime';
import {ListItem} from 'shared/ui-kit/ListItem';
import {FolderOpenOutlined} from 'shared/ui-kit/icons';
import {Descriptions, List} from 'shared/ui-kit/antd';
import InfiniteScroll from 'react-infinite-scroll-component';
import {TOrder} from 'shared/types/order';
import {TDescription} from 'shared/types/list';
import {routes} from 'shared/consts/route';
import {Typography} from 'antd';
import {IMeaning, STATUS_MEANING} from 'shared/consts/order';
import {centToDollar} from 'entities/Order/helpers';

const {Text} = Typography;

export interface IList<T> {
  items: Array<T>;
  total: number;
  fetchData?: () => void;
}

const descriptions: Array<TDescription<TOrder>> = [
  {
    label: 'Order ID:',
    dataIndex: 'objectId',
    key: 'objectId',
    render: (item: TOrder) => <Text>#{item?.objectId?.toUpperCase()}</Text>,
  },
  {
    label: 'Last Update:',
    dataIndex: 'updatedAt',
    key: 'updatedAt',
    render: (item: TOrder) => <DateTime>{item?.updatedAt}</DateTime>,
  },
  {
    label: 'Created:',
    dataIndex: 'createdAt',
    key: 'createdAt',
    render: (item: TOrder) => <DateTime>{item?.createdAt}</DateTime>,
  },
  {
    label: 'User:',
    dataIndex: 'User',
    key: 'user',
    render: (item: TOrder) =>
      item?.User?.objectId ? (
        <Link to={routes.usersView.get({id: item.User.objectId})}>
          {item?.User?.username} ({item.User?.fullName})
        </Link>
      ) : (
        '-'
      ),
  },
  {
    label: 'Status:',
    dataIndex: 'status',
    key: 'status',
    render: (item: TOrder) => <Text>{STATUS_MEANING[item.status as IMeaning]}</Text>,
  },
  {
    label: 'Delivery Status:',
    dataIndex: 'delivery_status',
    key: 'delivery_status',
    render: (item: TOrder) => <Text>{STATUS_MEANING[item.delivery_status as IMeaning]}</Text>,
  },

  {
    label: 'Shipping Address:',
    dataIndex: 'address',
    key: 'address',
    render: (item: TOrder) => (
      <Text>
        {item.region}, {item.state}, {item?.city}, {item?.address}, {item?.suite || ''}{' '}
      </Text>
    ),
  },
  {
    label: 'Price:',
    dataIndex: 'cardPrice',
    key: 'cardPrice',
    render: (item: TOrder) => <Text>{centToDollar(item?.cardPrice)}</Text>,
  },
];

const itemStyle = {padding: 8};

const renderItem = (item: TOrder) => (
  <Link to={routes.ordersView.get({id: item.objectId || ''})}>
    <ListItem key={item.id} title={`#${item.objectId?.toUpperCase() || ''}`} icon={<FolderOpenOutlined />}>
      <Descriptions column={{xs: 1, sm: 1, lg: 3}}>
        {descriptions.map((desc) => (
          <Descriptions.Item style={itemStyle} label={desc.label} key={desc.key}>
            {' '}
            {desc.render ? desc.render(item) : String(item[desc.dataIndex]) || '-'}
          </Descriptions.Item>
        ))}
      </Descriptions>
    </ListItem>
  </Link>
);

const listStyle = {
  wrapper: {
    height: 'calc(100vh - 95px)',
    overflow: 'auto',
    display: 'flex',
    flexDirection: 'column',
  } as DetailedHTMLProps<HTMLAttributes<HTMLDivElement>, HTMLDivElement>,
  scroller: {display: 'flex', flexDirection: 'column'} as DetailedHTMLProps<
    HTMLAttributes<HTMLDivElement>,
    HTMLDivElement
  >,
};

export const OrderList: React.FC<IList<object>> = ({items, fetchData, total}) => {
  return fetchData ? (
    <div style={listStyle.wrapper} id="scrollableDiv">
      <InfiniteScroll
        dataLength={items.length}
        next={fetchData}
        hasMore={items.length < total}
        style={listStyle.scroller}
        loader={<h4>Loading...</h4>}
        scrollableTarget="scrollableDiv">
        <List dataSource={items} bordered renderItem={renderItem} />
      </InfiniteScroll>
    </div>
  ) : (
    <List dataSource={items} bordered renderItem={renderItem} />
  );
};
