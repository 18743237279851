import styled, {createGlobalStyle} from 'styled-components';

export const GlobalStyle = createGlobalStyle`
  .ant-menu-inline-collapsed-tooltip { 
        display: none; 
  }
  .ant-page-header {
    padding: 0;
  }
  .ant-page-header-content {
    padding-left: 0;
  }
  .ant-page-header-heading {
    padding: 16px 16px;
  }
  ::-webkit-scrollbar {
    width: 5px;
  }

  ::-webkit-scrollbar-track {
    border-radius: 10px;
  }

  ::-webkit-scrollbar-thumb {
    background: #00c384;
    border-radius: 10px;
  }
  
  .ant-btn-primary {
    background-color: #00c384;
    border: none;
    font-weight: 600;

    &:hover, &:active, &:focus {
      background: #14855a !important;
      border: none !important;
    }
  }
  .ant-tag, .ant-tag-has-color {
    background-color: #00c384 !important;
    font-weight: 600 !important;
  }
  .ant-page-header-heading-title {
    h2 {
      margin: 0 !important;
    }
  }
  .ant-menu-item-selected {
    background-color: #00c384 !important;
    font-weight: 600 !important;
  }

  .ant-tabs-nav-wrap {
    margin: 0 16px;
  }
  .ant-descriptions {
    padding: 0 16px;
  }
  .ant-form, .ant-form-horizontal {
    padding: 16px !important;
  }
  .ant-breadcrumb {
    padding: 0 16px;
  }
  .ant-descriptions-item {
    padding: 8px;
  }
`;

export const Content = styled.div`
  height: 100vh;
  overflow-y: auto;
  background: #000;
  @media all and (min-width: 769px) {
    padding: 0 0px;
  }
`;
