import React from 'react';
import {Link} from 'react-router-dom';
import {Layout, Menu, Avatar} from 'shared/ui-kit/antd';
import {TMenuItem} from '../../types/menu';
import {SidebarTitle, Username} from './styles';
import {menuItems} from '../../consts/menu';
import {useSideBar} from '../../hooks/sidebar';
import {UserOutlined} from '@ant-design/icons';

const getItem = ({key, to, Icon, title, items}: TMenuItem) => {
  if (items && items.length > 0)
    return (
      <Menu.SubMenu
        key={key}
        title={
          <>
            {Icon && <Icon />}
            <span>{title}</span>
          </>
        }>
        {items.map((propsOfItem) => getItem(propsOfItem))}
      </Menu.SubMenu>
    );

  if (key === 'logout')
    return (
      <Menu.Item key={key}>
        {Icon && <Icon />}
        <span>{title}</span>
      </Menu.Item>
    );

  return (
    <Menu.Item key={key}>
      <Link to={to}>
        {Icon && <Icon />}
        <span>{title}</span>
      </Link>
    </Menu.Item>
  );
};

const sideBarStyle = {top: 16};

export const Sidebar: React.FC = () => {
  const {trigger, username, collapsed, currentItem, collapsedWidth, onItemClickHandler, toggleCollapseHandler} =
    useSideBar();
  return (
    <Layout.Sider
      width="250"
      collapsible
      trigger={trigger}
      collapsed={!!collapsed}
      collapsedWidth={collapsedWidth}
      onCollapse={toggleCollapseHandler}
      zeroWidthTriggerStyle={sideBarStyle}>
      <SidebarTitle>
        <Avatar icon={<UserOutlined />} />
        {!collapsed && <Username>{username}</Username>}
      </SidebarTitle>
      <Menu
        theme="dark"
        defaultSelectedKeys={['users']}
        selectedKeys={[`${currentItem}`]}
        mode="inline"
        onClick={(e) => onItemClickHandler(e)}>
        {menuItems.map((item) => getItem(item))}
      </Menu>
    </Layout.Sider>
  );
};
