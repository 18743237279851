declare global {
  interface Window {
    _env_: {
      REACT_ADMIN_SITE_URL: string | undefined;
      REACT_APP_APPLICATION_ID: string | undefined;
      REACT_APP_GRAPHQL_URI: string | undefined;
      REACT_APP_API_KEY: string | undefined;
    };
    __PRELOADED_STATE__: any;
  }
}

export const ADMIN_SITE_URL =
  typeof window !== 'undefined' && window._env_
    ? window._env_.REACT_ADMIN_SITE_URL
    : process.env.REACT_ADMIN_SITE_URL || 'https://admin-highlights.moonion.dev/';

export const APPLICATION_ID =
  typeof window !== 'undefined' && window._env_
    ? window._env_.REACT_APP_APPLICATION_ID
    : process.env.REACT_APP_APPLICATION_ID || 'highlights';

export const GRAPHQL_URI =
  typeof window !== 'undefined' && window._env_
    ? window._env_.REACT_APP_GRAPHQL_URI
    : process.env.REACT_APP_GRAPHQL_URI || 'https://server-highlights.moonion.dev/graphql';

export const API_KEY =
  typeof window !== 'undefined' && window._env_
    ? window._env_.REACT_APP_API_KEY
    : process.env.REACT_APP_API_KEY || 'rVKi0ZOVmcqZ8XIEYhjImDLwZUXH026K';
