import React from 'react';
import {CreateButton} from 'shared/ui-kit/Button/Create';
import {PageHeader} from 'shared/ui-kit/PageHeader';
import {Tag} from 'antd';
import {UsersList, useGetUserList} from 'entities/User';
import {routes} from 'shared/consts/route';

export const UserListWidget: React.FC = () => {
  const {items, total, loading, fetchData} = useGetUserList();
  return (
    <PageHeader
      title="Users"
      tags={<Tag color="#108ee9">{total}</Tag>}
      loading={loading}
      extra={[<CreateButton to={routes.usersCreate.path} key="createUser" />]}>
      <UsersList items={items} fetchData={fetchData} total={total} />
    </PageHeader>
  );
};
