import React from 'react';
import {Link} from 'react-router-dom';
import {Route} from 'antd/lib/breadcrumb/Breadcrumb';

export const renderBreadcrumbNavigation = (
  route: Route,
  params: any,
  routes: Array<Route>,
  paths: Array<string>,
): React.ReactNode => {
  const last = routes.indexOf(route) === routes.length - 1;
  return last ? <span>{route.breadcrumbName}</span> : <Link to={`/${paths.join('/')}`}>{route.breadcrumbName}</Link>;
};
