import {useQuery} from '@apollo/react-hooks';
import {message} from 'shared/ui-kit/antd';
import {GetRoles} from '../api';
import {TRoleItem} from 'shared/types/role';

export const useGetRoles = () => {
  const {data, error} = useQuery(GetRoles);

  error && message.error(error.message);

  const roles = data?.roles?.edges.map((item: TRoleItem) => item.node.name);
  return {roles};
};
