import React, {DetailedHTMLProps, HTMLAttributes} from 'react';
import {Link} from 'react-router-dom';
import {DateTime} from 'shared/ui-kit/DateTime';
import {ListItem} from 'shared/ui-kit/ListItem';
import {UserOutlined} from 'shared/ui-kit/icons';
import {Descriptions, List} from 'shared/ui-kit/antd';
import InfiniteScroll from 'react-infinite-scroll-component';
import {TUser} from 'shared/types/user';
import {routes} from 'shared/consts/route';
import {TDescription} from 'shared/types/list';
import {Typography} from 'antd';
const {Text} = Typography;

export interface IList<T> {
  items: Array<T>;
  total: number;
  fetchData?: () => void;
}

const descriptions: Array<TDescription<TUser>> = [
  {
    label: 'ID:',
    dataIndex: 'objectId',
    key: 'objectId',
    render: (item: TUser) => <Text>#{item?.objectId?.toUpperCase()}</Text>,
  },
  {
    label: 'Email:',
    dataIndex: 'email',
    key: 'email',
    render: (item: TUser) => <Text>{item?.email}</Text>,
  },
  {
    label: 'Created at:',
    dataIndex: 'createdAt',
    key: 'createdAt',
    render: (item: TUser) => <DateTime>{item?.createdAt || ''}</DateTime>,
  },
  {
    label: 'Updated at:',
    dataIndex: 'updatedAt',
    key: 'updatedAt',
    render: (item: TUser) => <DateTime>{item?.updatedAt || ''}</DateTime>,
  },
];
const itemStyle = {padding: 8};

const renderItem = (item: TUser) => (
  <Link to={routes.usersView.get({id: item.id || ''})}>
    <ListItem
      key={item.id}
      title={`${item.username || ''} ${item.fullName ? item.fullName : ''}`}
      icon={<UserOutlined />}>
      <Descriptions column={{xs: 1, sm: 1, lg: 3}}>
        {descriptions.map((desc) => (
          <Descriptions.Item style={itemStyle} label={desc.label} key={desc.key}>
            {' '}
            {desc.render ? desc.render(item) : String(item[desc.dataIndex]) || '-'}
          </Descriptions.Item>
        ))}
      </Descriptions>
    </ListItem>
  </Link>
);

const listStyle = {
  wrapper: {
    height: 'calc(100vh - 95px)',
    overflow: 'auto',
    display: 'flex',
    flexDirection: 'column',
  } as DetailedHTMLProps<HTMLAttributes<HTMLDivElement>, HTMLDivElement>,
  scroller: {display: 'flex', flexDirection: 'column'} as DetailedHTMLProps<
    HTMLAttributes<HTMLDivElement>,
    HTMLDivElement
  >,
};

export const UsersList: React.FC<IList<object>> = ({items, fetchData, total}) => {
  return fetchData ? (
    <div style={listStyle.wrapper} id="scrollableDiv">
      <InfiniteScroll
        dataLength={items.length}
        next={fetchData}
        hasMore={items.length < total}
        style={listStyle.scroller}
        loader={<h4>Loading...</h4>}
        scrollableTarget="scrollableDiv">
        <List dataSource={items} bordered renderItem={renderItem} />
      </InfiniteScroll>
    </div>
  ) : (
    <List dataSource={items} bordered renderItem={renderItem} />
  );
};
