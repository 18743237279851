import {gql} from '@apollo/client';

export const EditOrderQuery = gql`
  mutation updateOrder($id: ID!, $fields: UpdateOrderFieldsInput) {
    updateOrder(input: {id: $id, fields: $fields}) {
      order {
        id
        objectId
        status
        paymentType
        lastName
        firstName
        zip
        cardPrice
        payment_status
        city
        tax
        phone
        createdAt
        updatedAt
        shippingMethod
        state
        region
        address
        suite
        paymentRegion
        shippingPrice
        delivery_status
      }
    }
  }
`;

export const EditHighlightQuery = gql`
  mutation updateHighlight($id: ID!, $fields: UpdateHighlightFieldsInput) {
    updateHighlight(input: {id: $id, fields: $fields}) {
      highlight {
        objectId
      }
    }
  }
`;
