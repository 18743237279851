import React from 'react';
import {Store} from 'rc-field-form/lib/interface';
import {Form, Button, Input} from 'shared/ui-kit/antd';
import {IUserForm, TValues} from '../../types';
import {getFields, getUserFormFields, prepareValues} from '../../helpers';
import {RolesInput} from 'entities/Roles';
import {clearError, getError} from 'shared/lib/helpers/form';

export const UserForm: React.FC<IUserForm> = ({onSubmit, id, values, errors = {}, clearErrors}) => {
  const [form] = Form.useForm();
  const fields = values && getFields(values);
  const {username, email, password, rolesField} = getUserFormFields(id);

  const onFinish = (values: TValues) => onSubmit && onSubmit(prepareValues(values));

  const onSelectChange = (value: string[]) => {
    form.setFieldsValue({[rolesField.name]: value});
  };

  return (
    <Form
      form={form}
      onFinish={onFinish as (values: Store) => void}
      labelCol={{
        xs: {span: 24},
        sm: {span: 8},
      }}
      wrapperCol={{
        xs: {span: 24},
        sm: {span: 8},
      }}
      fields={fields}>
      <Form.Item
        label={username.label}
        name={username.name}
        rules={username.rules}
        validateStatus={getError(username.name, errors).validateStatus}
        help={getError(username.name, errors).help}>
        <Input placeholder={username.label} onChange={clearError(username.name, errors, clearErrors)} />
      </Form.Item>
      <Form.Item
        label={email.label}
        name={email.name}
        rules={email.rules}
        validateStatus={getError(email.name, errors).validateStatus}
        help={getError(email.name, errors).help}>
        <Input placeholder={email.label} type={email.type} onChange={clearError(email.name, errors, clearErrors)} />
      </Form.Item>
      <Form.Item label={password.label} name={password.name} rules={password.rules}>
        <Input.Password placeholder={password.label} />
      </Form.Item>
      <Form.Item
        label={rolesField.label}
        name={rolesField.name}
        rules={rolesField.rules}
        validateStatus={getError(rolesField.name, errors).validateStatus}
        help={getError(rolesField.name, errors).help}>
        <RolesInput
          defaultValue={values.roles || []}
          onChange={onSelectChange}
          placeholder={rolesField.placeholder}
          mode="multiple"
        />
      </Form.Item>

      <Form.Item
        wrapperCol={{
          xs: {span: 24, offset: 0},
          sm: {span: 16, offset: 8},
        }}>
        <Button type="primary" htmlType="submit">
          {id ? 'Update' : 'Create'}
        </Button>
      </Form.Item>
    </Form>
  );
};
