import {useQuery} from '@apollo/react-hooks';

import {message} from 'shared/ui-kit/antd';
import {GetSessionsByIdUser} from '../api';
import {useParams} from 'react-router-dom';
import {LIMIT_ELEMENTS_PAGINATION} from 'shared/consts/screen';
import {getErrorObject} from 'shared/lib/helpers/error';
import {TItemData, TSession} from 'shared/types/user';

export const useSessions = () => {
  const {loading, data, error, fetchMore} = useQuery(GetSessionsByIdUser, {
    variables: {first: LIMIT_ELEMENTS_PAGINATION},
  });
  const fetchData = async () => {
    try {
      await fetchMore({
        query: GetSessionsByIdUser,
        variables: {
          cursor:
            data?.searchSessions?.edges.length > 0
              ? data?.searchSessions?.edges[data.searchSessions.edges.length - 1].cursor
              : '',
          first: LIMIT_ELEMENTS_PAGINATION,
        },
        updateQuery: (previousResult, {fetchMoreResult}) => {
          const previousSessions = previousResult.searchSessions.edges;
          const newSessions = fetchMoreResult.searchSessions.edges;

          return {
            searchSessions: {
              edges: [...previousSessions, ...newSessions],
              count: fetchMoreResult.searchSessions.count,
              __typename: previousResult.searchSessions.__typename,
            },
          };
        },
      });
    } catch (e) {
      const error = getErrorObject(e);
      message.error(error.message);
    }
  };

  error && message.error(error.message);

  const items =
    data?.searchSessions?.edges.map((item: TItemData<TSession>) => ({
      ...item.node,
    })) || [];
  const total = data?.searchSessions?.count || 0;

  return {total, items, loading, fetchData};
};

export const useUserSession = () => {
  const {id} = useParams<{id: string}>();
  const {data, error, fetchMore} = useQuery(GetSessionsByIdUser, {
    variables: {id, first: LIMIT_ELEMENTS_PAGINATION},
  });

  const fetchData = async () => {
    try {
      await fetchMore({
        query: GetSessionsByIdUser,
        variables: {
          id,
          cursor:
            data?.searchSessions?.edges.length > 0
              ? data?.searchSessions?.edges[data.searchSessions.edges.length - 1].cursor
              : '',
          first: LIMIT_ELEMENTS_PAGINATION,
        },
        updateQuery: (previousResult, {fetchMoreResult}) => {
          const previousSessions = previousResult.searchSessions.edges;
          const newSessions = fetchMoreResult.searchSessions.edges;

          return {
            searchSessions: {
              edges: [...previousSessions, ...newSessions],
              count: fetchMoreResult.searchSessions.count,
              __typename: previousResult.searchSessions.__typename,
            },
          };
        },
      });
    } catch (e) {
      const error = getErrorObject(e);
      message.error(error.message);
    }
  };

  error && message.error(error.message);

  const items =
    data?.searchSessions?.edges.map((item: TItemData<TSession>) => ({
      ...item.node,
    })) || [];

  const total = data?.searchSessions?.count || 0;

  return {items, fetchData, total};
};
