import React from 'react';
import {PageHeader} from 'shared/ui-kit/PageHeader';
import {useEditOrder} from '../../hooks';
import {OrderForm} from '../OrderForm';
import {getRoutesEdit} from '../../helpers';

export const EditOrderForm: React.FC = () => {
  const {id, errors, values, loading, clearErrors, editOrderHandler} = useEditOrder();

  const routes = getRoutesEdit(id, values.objectId);

  return (
    <PageHeader title={`Edit #${values.objectId?.toUpperCase()}`} routes={routes} loading={loading}>
      <OrderForm id={id} values={values} errors={errors} clearErrors={clearErrors} onSubmit={editOrderHandler} />
    </PageHeader>
  );
};
