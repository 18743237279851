export const DELIVERY_STATUS = {
  payment_waiting: 'payment_waiting',
  batching: 'batching',
  sent: 'sent',
  mistake: 'mistake',
  canceled: 'canceled',
} as const;

export const ORDER_STATUS = {
  new: 'new',
  completed: 'completed',
  canceled: 'canceled',
  pending_question: 'pending_question',
} as const;

export const TRANSACTION_STATUS = {
  succeeded: 'succeeded',
  canceled: 'canceled',
  requires_action: 'requires_action',
  processing: 'processing',
} as const;

export type IMeaning = keyof typeof ORDER_STATUS | keyof typeof DELIVERY_STATUS | keyof typeof TRANSACTION_STATUS;

export const STATUS_MEANING: Record<IMeaning, string> = {
  new: 'New Order',
  payment_waiting: 'Wait For Payment From User',
  batching: 'Order Is Butching',
  sent: 'Order Was Sent',
  mistake: 'Address Has Mistake ',
  canceled: 'Canceled',
  completed: 'Order Was Completed',
  pending_question: 'Have Question to Cutomer',
  processing: 'Transaction Is Processing',
  succeeded: 'Transaction Was Completed',
  requires_action: 'Transaction Is Processing',
};

export const deliveryStatusOptions = [
  // {
  //   value: DELIVERY_STATUS.mistake,
  //   label: STATUS_MEANING[DELIVERY_STATUS.mistake as IMeaning],
  // },
  {
    value: DELIVERY_STATUS.batching,
    // label: STATUS_MEANING[DELIVERY_STATUS.batching as IMeaning] ,
    label: ' ',
  },
  {
    value: DELIVERY_STATUS.sent,
    label: STATUS_MEANING[DELIVERY_STATUS.sent as IMeaning],
  },
];

export const statusOptions = [
  // {
  //   value: ORDER_STATUS.pending_question,
  //   label: STATUS_MEANING[ORDER_STATUS.pending_question as IMeaning],
  // },
  {
    value: ORDER_STATUS.completed,
    label: STATUS_MEANING[ORDER_STATUS.completed as IMeaning],
  },
  {
    value: ORDER_STATUS.new,
    label: STATUS_MEANING[ORDER_STATUS.new as IMeaning],
  },
  {
    value: ORDER_STATUS.canceled,
    label: STATUS_MEANING[ORDER_STATUS.canceled as IMeaning],
  },
];

export const transactionStatusOptions = [
  {
    value: TRANSACTION_STATUS.processing,
    label: STATUS_MEANING[TRANSACTION_STATUS.processing as IMeaning],
  },
  {
    value: TRANSACTION_STATUS.canceled,
    label: STATUS_MEANING[TRANSACTION_STATUS.canceled as IMeaning],
  },
  {
    value: TRANSACTION_STATUS.requires_action,
    label: STATUS_MEANING[TRANSACTION_STATUS.requires_action as IMeaning],
  },
  {
    value: TRANSACTION_STATUS.succeeded,
    label: STATUS_MEANING[TRANSACTION_STATUS.succeeded as IMeaning],
  },
];
