import {ReactNode} from 'react';
import {TError} from '../../types/form';
import {ValidateStatus} from 'antd/lib/form/FormItem';

const checkErrors = (name: string, errors: TError): boolean | number => {
  return errors[name] && errors[name].length > 0;
};

const clearError = (name: string, errors: TError, clear: (name: string) => void) => () => {
  if (!checkErrors(name, errors)) return;
  clear(name);
};

const getError = (name: string, errors: TError): {validateStatus: ValidateStatus; help?: ReactNode} => {
  if (!checkErrors(name, errors)) return {validateStatus: '', help: undefined};
  return {
    validateStatus: 'error',
    help: errors[name][0],
  };
};

const throwError = (value: string) => {
  throw new Error(value);
};

export {clearError, getError, throwError};
