import React from 'react';
import {Layout, Card} from 'antd';
import {AuthForm} from './AuthForm';
import {useAuth} from '../../hooks/auth';

const loginStyle = {maxWidth: '100%', width: 400, margin: '24px auto'};

export const Login: React.FC = () => {
  const {loginHandler, loading, errors, clearErrors} = useAuth();
  return (
    <Layout>
      <Card style={loginStyle}>
        <AuthForm loading={loading} errors={errors} clearErrors={clearErrors} onSubmit={loginHandler} />
      </Card>
    </Layout>
  );
};
