import React from 'react';
import {Route, Routes as Switch, Navigate} from 'react-router-dom';
import {LayoutWidget} from 'widgets/Layout';
import {UserListPage} from './User/List';
import {CreateUserPage} from './User/Create';
import {EditUserPage} from './User/Edit';
import {ViewUserPage} from './User/View';
import {SessionsPage} from './Sessions';
import {AuthPage} from './Auth';
import {EditOrderPage} from './Order/Edit';
import {ViewOrderPage} from './Order/View';
import {OrderListPage} from './Order/List';
import {routes} from 'shared/consts/route';

export const Routes: React.FC = () => {
  return (
    <LayoutWidget
      privateChildren={<AuthPage />}
      publicChildren={
        <Switch>
          <Route key="users-create" path={routes.usersCreate.path} element={<CreateUserPage />} />
          <Route key="users-edit" path={routes.usersEdit.path} element={<EditUserPage />} />
          <Route key="users-view" path={routes.usersView.path} element={<ViewUserPage />} />
          <Route key="users" path={routes.users.path} element={<UserListPage />} />
          <Route key="orders-edit" path={routes.ordersEdit.path} element={<EditOrderPage />} />
          <Route key="orders-view" path={routes.ordersView.path} element={<ViewOrderPage />} />
          <Route key="orders" path={routes.orders.path} element={<OrderListPage />} />
          {/* <Route
            key="roles"
            path={routes.roles.path}
            element={<h1>Roles</h1>}
          /> */}
          <Route key="sessions" path={routes.sessions.path} element={<SessionsPage />} />
          <Route path={routes.all.path} element={<Navigate to={routes.users.path} />} />
        </Switch>
      }></LayoutWidget>
  );
};
