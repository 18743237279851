import {useLocation} from 'react-router-dom';
import {useMutation} from '@apollo/react-hooks';
import {useLocalStorageState} from '@umijs/hooks';
import {isMobileDevice} from 'shared/lib/helpers/screen';
import {Logout} from 'shared/api/queries/auth';
import {useSessionStore} from 'shared/model';
export const useSideBar = () => {
  const [collapsed, setCollapsed] = useLocalStorageState('collapsedSidebar', isMobileDevice ? 1 : 0);
  const {session, clearSession} = useSessionStore((state) => state);
  const [logout] = useMutation(Logout);

  const toggleCollapseHandler = () => {
    setCollapsed(collapsed === 0 ? 1 : 0);
  };

  const logoutHandler = async () => {
    try {
      await logout();
      localStorage.removeItem('token');
      clearSession && clearSession();
    } catch (e) {}
  };

  const onItemClickHandler = ({key}: {key: string}) => {
    if (key === 'logout') {
      logoutHandler();
    } else {
      isMobileDevice && toggleCollapseHandler();
    }
  };

  const currentItem = useLocation().pathname.split('/')[1];
  const collapsedWidth = isMobileDevice ? 0 : 80;
  const trigger = isMobileDevice ? true : undefined;
  const username = session?.user?.username || '';

  return {
    trigger,
    username,
    collapsed,
    currentItem,
    collapsedWidth,
    onItemClickHandler,
    toggleCollapseHandler,
  };
};
