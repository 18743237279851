import {useQuery} from '@apollo/react-hooks';

import {message} from 'shared/ui-kit/antd';
import {GetMoreUsers} from '../api';
import {LIMIT_ELEMENTS_PAGINATION} from 'shared/consts/screen';
import {getErrorObject} from 'shared/lib/helpers/error';
import {TItemData, TUser} from 'shared/types/user';

export const useGetUserList = () => {
  const {
    data,
    loading,
    fetchMore,
    error: errorGetUser,
  } = useQuery(GetMoreUsers, {
    variables: {first: LIMIT_ELEMENTS_PAGINATION},
  });

  const fetchData = async () => {
    try {
      await fetchMore({
        query: GetMoreUsers,
        variables: {
          cursor: data?.users?.edges.length > 0 ? data?.users?.edges[data.users.edges.length - 1].cursor : '',
          first: LIMIT_ELEMENTS_PAGINATION,
        },
        updateQuery: (previousResult, {fetchMoreResult}) => {
          const previousUsers = previousResult.users.edges;
          const newUsers = fetchMoreResult.users.edges;
          return {
            users: {
              edges: [...previousUsers, ...newUsers],
              count: fetchMoreResult.users.count,
              __typename: previousResult.users.__typename,
            },
          };
        },
      });
    } catch (e) {
      const error = getErrorObject(e);
      message.error(error.message);
    }
  };

  errorGetUser && message.error(errorGetUser.message);

  const total = data?.users?.count || 0;
  const items = data?.users?.edges.map((item: TItemData<TUser>) => ({...item.node})) || [];
  return {
    items,
    total,
    loading,
    fetchData,
  };
};
