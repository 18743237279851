export const getAuthFormFields = () => {
  return {
    username: {
      name: 'username',
      placeholder: 'Username',
      rules: [
        {
          required: true,
          message: 'Please input your username!',
        },
      ],
    },
    password: {
      name: 'password',
      placeholder: 'Password',
      rules: [
        {
          required: true,
          message: 'Please input your password!',
        },
      ],
    },
  };
};
