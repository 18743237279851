import React, {PropsWithChildren, ReactNode} from 'react';
import {Typography, PageHeader as AntPageHeader, Spin, Tag} from 'antd';
import {Route} from 'antd/lib/breadcrumb/Breadcrumb';
import styled from 'styled-components';
import {renderBreadcrumbNavigation} from '../../lib/helpers/links';

const PageHeaderComponent = styled(AntPageHeader)`
  &.has-footer {
    padding-bottom: 16px;
  }
  @media all and (max-width: 769px) {
    .ant-breadcrumb,
    .ant-page-header-heading-title {
      margin-left: 20px;
    }
    .ant-page-header-heading-extra {
      float: right;
      width: auto;
      padding-top: 0;
    }
  }
`;

interface Props {
  loading: boolean;
  title: string;
  extra?: ReactNode;
  routes?: Route[];
  subTitle?: React.ReactNode;
  footer?: React.ReactNode;
  tags?: React.ReactElement<typeof Tag> | React.ReactElement<typeof Tag>[];
}

export const PageHeader: React.FC<PropsWithChildren<Props>> = ({children, title, loading = false, routes, ...rest}) => {
  return (
    <Spin spinning={loading}>
      <PageHeaderComponent
        title={<Typography.Title level={2}>{title}</Typography.Title>}
        ghost={false}
        breadcrumb={{routes, itemRender: renderBreadcrumbNavigation}}
        {...rest}>
        {children}
      </PageHeaderComponent>
    </Spin>
  );
};
