import React from 'react';
import {DefaultButton} from 'shared/ui-kit/Button/Default';

type BlockUserPropsT = {
  onClick?: () => void;
  status: string;
  icon?: React.ReactNode;
};

export const BlockUser = ({icon, status, onClick}: BlockUserPropsT) => {
  return (
    <DefaultButton icon={icon} onClick={onClick}>
      {status}
    </DefaultButton>
  );
};
