import React from 'react';
import {PageHeader} from 'shared/ui-kit/PageHeader';
import {Tag} from 'antd';
import {OrderList, useGetOrderList} from 'entities/Order';

export const OrderListWidget: React.FC = () => {
  const {items, total, loading, fetchData} = useGetOrderList();
  return (
    <PageHeader title="Orders" tags={<Tag color="#108ee9">{total}</Tag>} loading={loading}>
      <OrderList items={items} fetchData={fetchData} total={total} />
    </PageHeader>
  );
};
