import React from 'react';
import {Layout, BackTop} from 'antd';
import {Sidebar} from '../Sidebar/Sidebar';
import {Content, GlobalStyle} from './styles';
import {useFetchStoreData} from '../../hooks/store';
import Spin from 'shared/ui-kit/Spin';

const layoutStyle = {minHeight: '100vh'};

export const LayoutWidget: React.FC<{
  privateChildren: React.ReactNode;
  publicChildren: React.ReactNode;
}> = ({publicChildren, privateChildren}) => {
  const {loading, session} = useFetchStoreData();
  if (loading) return <Spin />;
  if (!session) {
    return <>{privateChildren}</>;
  }
  return (
    <>
      <GlobalStyle />
      <BackTop />
      <Layout style={layoutStyle}>
        <Sidebar />
        <Layout>
          <Content>{publicChildren}</Content>
        </Layout>
      </Layout>
    </>
  );
};
