import React from 'react';
import {Select as AntSelect, SelectProps} from 'antd';

interface ISelect {
  roles: Array<string>;
  placeholder: string;
  mode: 'multiple' | 'tags';
  onChange: (value: string[]) => void;
  defaultValue: string[];
}

export const Select: React.FC<ISelect> = ({roles, placeholder, mode, onChange, defaultValue}) => {
  return (
    <AntSelect value={defaultValue as SelectProps['value']} onChange={onChange} mode={mode} placeholder={placeholder}>
      {roles?.map((role) => (
        <AntSelect.Option key={role} value={role}>
          {role}
        </AntSelect.Option>
      ))}
    </AntSelect>
  );
};
