export const routes = [
  {
    path: 'users',
    breadcrumbName: 'Users',
  },
  {
    path: '',
    breadcrumbName: '',
  },
];
