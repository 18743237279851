export const routes = [
  {
    path: 'orders',
    breadcrumbName: 'Orders',
  },
  {
    path: '',
    breadcrumbName: '',
  },
];
