import {gql} from '@apollo/client';

export const GetOrderById = gql`
  query getOrderById($id: ID!) {
    order(id: $id) {
      id
      objectId
      status
      paymentType
      lastName
      firstName
      zip
      cardPrice
      payment_status
      city
      tax
      phone
      createdAt
      updatedAt
      shippingMethod
      state
      region
      address
      suite
      paymentRegion
      shippingPrice
      delivery_status
      User {
        id
        objectId
        fullName
        username
      }
      Transactions {
        ... on Transaction {
          objectId
          paymentMethodId
          status
        }
      }
      Highlight {
        objectId
        NFCtags {
          ... on Element {
            value
          }
        }
        date
        location
        createdAt
        description
        Preview {
          file {
            url
          }
        }
        Video {
          file {
            url
          }
        }
      }
    }
  }
`;

export const GetMoreOrders = gql`
  query MoreOrders($cursor: String, $first: Int, $order: [OrderOrder!]) {
    orders(after: $cursor, first: $first, order: $order) {
      count
      edges {
        cursor
        node {
          id
          objectId
          status
          paymentType
          cardPrice
          payment_status
          tax
          createdAt
          updatedAt
          city
          state
          region
          address
          suite
          shippingMethod
          shippingPrice
          delivery_status
          User {
            id
            objectId
            fullName
            username
          }
        }
      }
    }
  }
`;
