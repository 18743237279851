import {gql} from '@apollo/client';

export const CreateUser = gql`
  mutation CreateUser($username: String!, $password: String!, $email: String!, $roles: [String!]!) {
    adminCreateUser(fields: {username: $username, email: $email, password: $password, roles: $roles}) {
      user {
        id
      }
    }
  }
`;

export const EditUserQuery = gql`
  mutation updateUser($id: ID!, $username: String, $password: String, $email: String, $roles: [String!]) {
    adminUpdateUser(id: $id, fields: {username: $username, password: $password, email: $email, roles: $roles}) {
      user {
        id
      }
    }
  }
`;
