import React from 'react';
import {CheckIcon} from './styles';
import {DateTime} from 'shared/ui-kit/DateTime';
import {UserSessions} from '../Session/UserSessions';
import {Tabs} from 'shared/ui-kit/antd';
import {TUser} from 'shared/types/user';
import {TDescription} from 'shared/types/list';
import {Typography} from 'antd';
const {Text} = Typography;

export const descriptions: Array<TDescription<TUser>> = [
  {
    label: 'ID:',
    dataIndex: 'objectId',
    key: 'objectId',
    render: (item: TUser) => <Text>#{item?.objectId?.toUpperCase()}</Text>,
  },
  {
    label: 'Username:',
    dataIndex: 'username',
    key: 'username',
    render: (item: TUser) => <Text>{item?.username}</Text>,
  },
  {
    label: 'Email:',
    dataIndex: 'email',
    key: 'email',
    render: (item: TUser) => {
      return (
        <>
          <Text>{item.email}</Text>
          {item.emailVerified && <CheckIcon />}
        </>
      );
    },
  },
  // {
  //   label: 'Roles:',
  //   key: 'roles',
  //   dataIndex: 'roles',
  //   render: (item: TUser) => {
  //     return item?.roles ? item.roles.join(', ') : '-';
  //   },
  // },
  {
    label: 'Created at:',
    dataIndex: 'createdAt',
    key: 'createdAt',
    render: (item: TUser) => <DateTime>{item?.createdAt || ''}</DateTime>,
  },
  {
    label: 'Updated at:',
    dataIndex: 'updatedAt',
    key: 'updatedAt',
    render: (item: TUser) => <DateTime>{item?.updatedAt || ''}</DateTime>,
  },
  {
    label: 'Status:',
    dataIndex: 'status',
    key: 'status',
    render: (item: TUser) => <Text>{item?.status}</Text>,
  },
];

const items = [{key: 'Sessions', label: 'Sessions', children: <UserSessions />}];

export const TabMenu = () => <Tabs defaultActiveKey="Sessions" items={items} />;
