import React from 'react';
import {Store} from 'rc-field-form/lib/interface';
import {Form, Button, Spin, Typography, Input} from 'antd';
import {UserOutlined, LockOutlined} from 'shared/ui-kit/icons';
import {getAuthFormFields} from '../../helpers/fields';
import {IForm} from '../../types/auth';
import {clearError, getError} from 'shared/lib/helpers/form';

export const AuthForm: React.FC<IForm> = ({loading, onSubmit, errors, clearErrors}) => {
  const {username, password} = getAuthFormFields();
  return (
    <Spin spinning={loading} size="large">
      <Form onFinish={onSubmit as (values: Store) => void} className="login-form">
        <Typography.Title>Admin</Typography.Title>
        <Form.Item
          name={username.name}
          rules={username.rules}
          validateStatus={getError(username.name, errors).validateStatus}
          help={getError(username.name, errors).help}>
          <Input
            prefix={<UserOutlined />}
            placeholder={username.placeholder}
            onChange={clearError(username.name, errors, clearErrors)}
          />
        </Form.Item>
        <Form.Item
          name={password.name}
          rules={password.rules}
          validateStatus={getError(password.name, errors).validateStatus}
          help={getError(password.name, errors).help}>
          <Input.Password
            prefix={<LockOutlined />}
            placeholder={password.placeholder}
            onChange={clearError(password.name, errors, clearErrors)}
          />
        </Form.Item>
        <Form.Item>
          <Button type="primary" style={loginStyle.button} htmlType="submit" className="login-form-button">
            Log in
          </Button>
        </Form.Item>
      </Form>
    </Spin>
  );
};

const loginStyle = {
  button: {
    backgroundColor: '#00ce8c',
    borderColor: '#00ce8c',
    fontWeight: '600',
  },
};
