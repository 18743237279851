import React, {DetailedHTMLProps, HTMLAttributes} from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';
import {Link} from 'react-router-dom';
import {routes} from 'shared/consts/route';
import {TDescription} from 'shared/types/list';
import {TSession} from 'shared/types/user';
import {DateTime} from 'shared/ui-kit/DateTime';
import {ListItem} from 'shared/ui-kit/ListItem';
import {Descriptions, List} from 'shared/ui-kit/antd';
import {FileTextOutlined} from 'shared/ui-kit/icons';

export interface IList<T> {
  items: Array<T>;
  fetchData?: () => void;
  total: number;
}

const descriptions: Array<TDescription<TSession>> = [
  {
    label: 'User:',
    dataIndex: 'user',
    key: 'username',
    render: (item: TSession) =>
      item?.user?.id ? <Link to={routes.usersView.get({id: item.user.id})}>{item?.user?.username || '-'}</Link> : '-',
  },
  // {
  //   label: 'Session Token:',
  //   dataIndex: 'sessionToken',
  //   key: 'sessionToken',
  // },
  // {
  //   label: 'Created with:',
  //   dataIndex: 'createdWith',
  //   key: 'createdWith',
  //   render: (item: TSession) =>
  //     `${item?.createdWith?.action || '-'},
  //     ${item?.createdWith?.authProvider || '-'}`,
  // },
  // {
  //   label: 'Restricted:',
  //   dataIndex: 'restricted',
  //   key: 'restricted',
  //   render: (item: TSession) => (item?.restricted ? 'True' : 'False'),
  // },
  // {
  //   label: 'InstallationId:',
  //   dataIndex: 'installationId',
  //   key: 'installationId',
  // },
  {
    label: 'Expires at:',
    dataIndex: 'expiresAt',
    key: 'expiresAt',
    render: (item: TSession) => <DateTime>{item?.expiresAt || ''}</DateTime>,
  },
  {
    label: 'Created at:',
    dataIndex: 'createdAt',
    key: 'createdAt',
    render: (item: TSession) => <DateTime>{item?.createdAt || ''}</DateTime>,
  },
  {
    label: 'Updated at:',
    dataIndex: 'updatedAt',
    key: 'updatedAt',
    render: (item: TSession) => <DateTime>{item?.updatedAt || ''}</DateTime>,
  },
];
const itemStyle = {padding: 8};

const renderItem = (item: TSession) => (
  <ListItem key={item.id} title={`ID: ${item.objectId || '-'}`} icon={<FileTextOutlined />} actions={[]}>
    <Descriptions column={{xs: 1, sm: 1, lg: 3}}>
      {descriptions.map((desc) => (
        <Descriptions.Item style={itemStyle} label={desc.label} key={desc.key}>
          {' '}
          {desc.render ? desc.render(item) : String(item[desc.dataIndex]) || '-'}
        </Descriptions.Item>
      ))}
    </Descriptions>
  </ListItem>
);
const listStyle = {
  wrapper: {
    height: 'calc(100vh - 95px)',
    overflow: 'auto',
    display: 'flex',
    flexDirection: 'column',
  } as DetailedHTMLProps<HTMLAttributes<HTMLDivElement>, HTMLDivElement>,
  scroller: {display: 'flex', flexDirection: 'column'} as DetailedHTMLProps<
    HTMLAttributes<HTMLDivElement>,
    HTMLDivElement
  >,
};

export const SessionsList: React.FC<IList<object>> = ({items, fetchData, total}) => {
  return fetchData ? (
    <div style={listStyle.wrapper} id="scrollableDiv">
      <InfiniteScroll
        next={fetchData}
        hasMore={items.length < total}
        dataLength={items.length}
        style={listStyle.scroller}
        loader={<h4>Loading...</h4>}
        scrollableTarget="scrollableDiv">
        <List dataSource={items} bordered renderItem={renderItem} />
      </InfiniteScroll>
    </div>
  ) : (
    <List dataSource={items} bordered renderItem={renderItem} />
  );
};
