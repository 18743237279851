import {gql} from '@apollo/client';

export const GetUserById = gql`
  query getUserById($id: ID!) {
    user(id: $id) {
      id
      objectId
      username
      fullName
      email
      emailVerified
      createdAt
      updatedAt
      status
    }
  }
`;

export const BlockUserRequest = gql`
  mutation updateUser($id: ID!, $status: UserStatuses!) {
    adminUpdateUser(id: $id, fields: {status: $status}) {
      user {
        id
      }
    }
  }
`;

export const DeleteUserRequest = gql`
  mutation deleteUser($id: ID!) {
    adminDeleteUser(id: $id) {
      user {
        id
      }
    }
  }
`;

export const GetMoreUsers = gql`
  query MoreUsers($cursor: String, $first: Int) {
    users(after: $cursor, first: $first) {
      count
      edges {
        cursor
        node {
          id
          objectId
          username
          fullName
          email
          createdAt
          updatedAt
          status
        }
      }
    }
  }
`;
