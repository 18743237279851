import {useState} from 'react';
import {useMutation} from '@apollo/react-hooks';
import {message} from 'shared/ui-kit/antd';

import {GetRolesByIdUser} from 'entities/Roles';
import {TLoginRes} from '../types/auth';
import {Login} from '../api/queries';
import {useSessionStore} from 'shared/model';
import {useImperativeQuery} from 'shared/api/hooks/queries';
import {TLoginReq} from 'shared/types/auth';
import {throwError} from 'shared/lib/helpers/form';
import {TRoleItem} from 'shared/types/role';
import {getErrorObject} from 'shared/lib/helpers/error';
import {Logout} from 'shared/api/queries/auth';

export const useAuth = () => {
  const [login, {loading}] = useMutation<TLoginRes, TLoginReq>(Login);
  const getRoles = useImperativeQuery(GetRolesByIdUser);
  const [errors, setErrors] = useState({});
  const {setSession, clearSession} = useSessionStore((state) => state);
  const [logout] = useMutation(Logout);

  const loginHandler = async (values: TLoginReq) => {
    const removeToken = () => localStorage.removeItem('token');
    try {
      const {data} = await login({variables: values});

      if (!data) throwError("Login Data hasn't been received!");

      data?.logIn.viewer.sessionToken && localStorage.setItem('token', data.logIn.viewer.sessionToken);

      const {data: rolesData} = await getRoles({
        id: data?.logIn.viewer.user.id,
      });

      if (!rolesData) throwError("During Login The Role hasn't been received!");

      const admin = rolesData.roles.edges.find((item: TRoleItem) => {
        return item.node.name === 'admin';
      });
      if (!admin && clearSession) {
        await logout();
        removeToken();
        clearSession();
        return message.error('Only admin can login!');
      }
      setSession && data?.logIn?.viewer && setSession(data.logIn.viewer);
    } catch (e) {
      console.log(e);
      const error = getErrorObject(e);
      removeToken();
      setErrors({username: [error.message]});
    }
  };

  const clearErrors = (name: string) => setErrors({...errors, [name]: []});

  return {loading, loginHandler, errors, clearErrors};
};
