import {useCallback, useEffect} from 'react';
import {GraphQLError} from 'graphql';
import {message} from 'shared/ui-kit/antd';

import {Current} from '../api/queries';
import {useImperativeQuery} from 'shared/api/hooks/queries';
import {useSessionStore} from 'shared/model';
import {getErrorObject} from 'shared/lib/helpers/error';

export const useFetchStoreData = () => {
  const {session, loading, toggleLoading, setSession} = useSessionStore((state) => ({
    ...state,
  }));
  const getCurrentSession = useImperativeQuery(Current);

  const isSessionError = (errors: ReadonlyArray<GraphQLError>) =>
    errors.find((error) => error && error.extensions && error.extensions.code === 209);

  async function _fetchData() {
    toggleLoading();
    try {
      const {data: currentSession} = await getCurrentSession();
      if (currentSession && !session && setSession) setSession(currentSession.viewer);
    } catch (e) {
      const error = getErrorObject(e);
      localStorage.removeItem('token');
      if (
        error &&
        error.graphQLErrors &&
        !isSessionError(error.graphQLErrors) &&
        error.networkError?.message !== 'Response not successful: Received status code 403'
      )
        message.error(error.message);
    }
    toggleLoading();
  }

  const fetchData = useCallback(_fetchData, []);

  useEffect(() => {
    fetchData();
  }, [fetchData]);
  return {session, loading};
};
