import React from 'react';
import {PageHeader} from 'shared/ui-kit/PageHeader';
import {getRoutesEdit} from '../../helpers';
import {useEditUser} from '../../hooks';
import {UserForm} from '../UserForm';

export const EditUserForm: React.FC = () => {
  const {id, user, errors, values, loading, clearErrors, editUserHandler} = useEditUser();
  const routes = getRoutesEdit(id, user?.username);

  return (
    <PageHeader title={`Edit ${values.username}`} loading={loading} routes={routes}>
      <UserForm id={id} values={values} errors={errors} clearErrors={clearErrors} onSubmit={editUserHandler} />
    </PageHeader>
  );
};
