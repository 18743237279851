import React from 'react';
import {Descriptions} from 'shared/ui-kit/antd';
import {EyeInvisibleOutlined, EyeOutlined} from 'shared/ui-kit/icons';
import {PageHeader} from 'shared/ui-kit/PageHeader';
import {EditButton} from 'shared/ui-kit/Button/Edit';
import {routes as viewRoute} from '../../consts/view';
import {useViewUser} from '../../hooks/view';
import {TabMenu, descriptions} from './Components';
import {BlockUser, getItemBlockStatus} from 'features/BlockUser';
import {DeleteUser} from 'features/DeleteUser';
import {StatusTypes} from 'shared/consts/user';
import {routes} from 'shared/consts/route';

const {Item} = Descriptions;

export const ViewUser: React.FC = () => {
  const {item, loading, onBlock, onDelete} = useViewUser();

  if (item && item.id && item.username) {
    viewRoute[1].path = item.id;
    viewRoute[1].breadcrumbName = item.username;
  }

  const icon = item?.status === StatusTypes.block ? <EyeOutlined /> : <EyeInvisibleOutlined />;
  const status = getItemBlockStatus(item);

  return (
    <PageHeader
      title={item?.username || 'No username'}
      loading={loading}
      routes={viewRoute}
      extra={[
        <BlockUser key={'blockUser'} onClick={onBlock} icon={icon} status={status} />,
        <DeleteUser key={'deleteUser'} onDelete={onDelete} />,
        <EditButton to={item?.id && routes.usersEdit.get({id: item.id})} key={'editUser'} />,
      ]}
      footer={<TabMenu />}>
      {item && (
        <Descriptions column={{xs: 1, sm: 1, md: 2, lg: 3}}>
          {descriptions.map((desc) => (
            <Item key={desc.key} label={desc.label} span={desc.span || 1}>
              {' '}
              {desc.render ? desc.render(item) : item[desc.dataIndex] || '-'}
            </Item>
          ))}
        </Descriptions>
      )}
    </PageHeader>
  );
};
