import {TValues} from '../types';
import {TOrder} from 'shared/types/order';

export const routes = [
  {
    path: 'users',
    breadcrumbName: 'Users',
  },
  {
    path: ``,
    breadcrumbName: `Add`,
  },
];

export const prepareValues = (values: TValues) => {
  const data: TOrder = {
    delivery_status: values.delivery_status,
    status: values.status,
    address: values.address,
    state: values.state,
    city: values.city,
    suite: values.suite,
  };

  const nfc = values?.nfcTags?.replaceAll(' ', '').length ? values?.nfcTags?.replaceAll(' ', '').split(',') : [];
  return {
    data: data,
    nfc: nfc,
  };
};

export const getFields = (values: TOrder) => {
  return [
    {
      name: ['region'],
      value: values?.region || '',
    },
    {
      name: ['state'],
      value: values?.state || '',
    },
    {
      name: ['city'],
      value: values?.city || '',
    },
    {
      name: ['address'],
      value: values?.address || '',
    },
    {
      name: ['suite'],
      value: values?.suite || '',
    },
    {
      name: ['delivery_status'],
      value: values.delivery_status,
    },
    {
      name: ['status'],
      value: values?.status,
    },
    {
      name: ['transaction_status'],
      value: values.Transactions?.[0]?.status || '',
    },
    {
      name: ['nfcTags'],
      value: values.Highlight?.NFCtags?.map((item) => item?.value)?.join(', ') || '',
    },
  ];
};

export const getOrderFormFields = () => {
  return {
    region: {
      label: 'Region',
      name: 'region',
      rules: [
        {
          message: 'Please input Order Region!',
        },
      ],
    },
    state: {
      label: 'State',
      name: 'state',
      rules: [
        {
          required: true,
          message: 'Please input Order State!',
        },
      ],
    },
    city: {
      label: 'City',
      name: 'city',
      rules: [
        {
          required: true,
          message: 'Please input Order City!',
        },
      ],
    },
    address: {
      label: 'Address',
      name: 'address',
      rules: [
        {
          required: true,
          message: 'Please input Order Address!',
        },
      ],
    },
    suite: {
      label: 'Suite',
      name: 'suite',
      rules: [
        {
          required: true,
          message: 'Please input Order Suite number!',
        },
      ],
    },
    status: {
      label: 'Status',
      name: 'status',
      rules: [
        {
          required: true,
          message: 'Please input Order Status!',
        },
      ],
    },
    delivery_status: {
      label: 'Delivery Status',
      name: 'delivery_status',
      rules: [
        {
          required: true,
          message: 'Please input Delivery Status!',
        },
      ],
    },
    transaction_status: {
      label: 'Payment Status',
      name: 'transaction_status',
      rules: [
        {
          message: 'Please input Transaction Status!',
        },
      ],
    },
    nfcTags: {
      label: 'NFC Tags',
      name: 'nfcTags',
      rules: [
        {
          required: false,
          validator: async (_: any, nfcTags: string) => {
            const regex = new RegExp(/^([a-zA-Z0-9]+,?\s*)+$/);
            if (!regex.test(nfcTags)) {
              return Promise.reject(new Error('invalid format entered. Example: 1111111111, 2222222222'));
            }
            const nfc = nfcTags?.replaceAll(' ', '').length ? nfcTags?.replaceAll(' ', '').split(',') : [];
            const unique = [...new Set(nfc)];
            if (nfc.length && nfc.length !== unique.length) {
              return Promise.reject(new Error('NFC tags must be unique'));
            }
            return Promise.resolve();
          },
        },
      ],
    },
  };
};

export const getRoutesEdit = (id?: string, name?: string) => {
  return [
    {
      path: 'orders',
      breadcrumbName: 'Orders',
    },
    {
      path: name || id || '',
      breadcrumbName: name || id || '',
    },
    {
      path: 'edit',
      breadcrumbName: 'Edit',
    },
  ];
};
