import React, {DetailedHTMLProps, HTMLAttributes} from 'react';
import {Descriptions, Divider} from 'shared/ui-kit/antd';
import {PageHeader} from 'shared/ui-kit/PageHeader';
import {EditButton} from 'shared/ui-kit/Button/Edit';
import {useViewOrder} from '../hooks/view';
import {routes as viewRoute} from '../consts/view';
import {DateTime} from 'shared/ui-kit/DateTime';
import {TDescription} from 'shared/types/list';
import {TOrder} from 'shared/types/order';
import {routes} from 'shared/consts/route';
import {IMeaning, STATUS_MEANING} from 'shared/consts/order';
import {Typography} from 'antd';
import {centToDollar} from 'entities/Order';
import {Link} from 'react-router-dom';
const {Text} = Typography;

const itemStyle = {padding: 8};

const deviderStyle = {
  borderTop: '1px solid white',
  display: 'flex',
  width: '98%',
  margin: '0 auto',
  marginTop: '30px',
  marginBottom: '30px',
};

export const ViewOrder: React.FC = () => {
  const {item, loading} = useViewOrder();

  if (item && item.objectId) {
    viewRoute[1].path = item.objectId;
    viewRoute[1].breadcrumbName = item.objectId;
  }

  return (
    <PageHeader
      title={`#${item?.objectId?.toUpperCase() || ''}`}
      loading={loading}
      routes={viewRoute}
      extra={[<EditButton to={item?.objectId && routes.ordersEdit.get({id: item.objectId})} key={'editOrder'} />]}>
      {item && (
        <Descriptions column={{xs: 1, sm: 1, md: 2, lg: 3}}>
          {descriptions.map((desc) => (
            <Descriptions.Item style={itemStyle} key={desc.key} label={desc.label} span={desc.span || 1}>
              {' '}
              {desc.render ? desc.render(item) : String(item[desc.dataIndex]) || '-'}
            </Descriptions.Item>
          ))}
        </Descriptions>
      )}
      <Divider style={deviderStyle} />
      <Descriptions layout="horizontal">
        <Descriptions.Item style={itemStyle}>
          <Text>NFC Tags:</Text>
        </Descriptions.Item>
        <Descriptions.Item style={itemStyle}>
          <Text>{item?.Highlight?.NFCtags?.map((item) => `# ${item?.value}`)?.join(', ')}</Text>
        </Descriptions.Item>
      </Descriptions>
      <Divider style={deviderStyle} />
      <div style={highlightInfoWrapperStyle}>
        <img src={item?.Highlight?.Preview?.file?.url} alt="highlight image" />
        {item && (
          <Descriptions layout="horizontal">
            {descriptionsHighlight.map((desc) => (
              <Descriptions.Item style={itemStyle} key={desc.key} label={desc.label} span={desc.span || 1}>
                {' '}
                {desc.render ? desc.render(item) : String(item[desc.dataIndex]) || '-'}
              </Descriptions.Item>
            ))}
          </Descriptions>
        )}
      </div>
    </PageHeader>
  );
};

const highlightInfoWrapperStyle = {
  display: 'flex',
  flexDirection: 'row',
  padding: '0 16px',
  paddingBottom: '32px',
} as DetailedHTMLProps<HTMLAttributes<HTMLDivElement>, HTMLDivElement>;

const descriptions: Array<TDescription<TOrder>> = [
  {
    label: 'Order ID:',
    dataIndex: 'objectId',
    key: 'objectId',
    render: (item: TOrder) => <Text>#{item?.objectId?.toUpperCase()}</Text>,
  },
  {
    label: 'User:',
    dataIndex: 'User',
    key: 'user',
    render: (item: TOrder) =>
      item?.User?.objectId ? (
        <Link to={routes.usersView.get({id: item.User.objectId})}>
          {item?.User?.username} ({item.User?.fullName})
        </Link>
      ) : (
        '-'
      ),
  },
  {
    label: 'Phone:',
    dataIndex: 'phone',
    key: 'phone',
  },
  {
    label: 'Status:',
    dataIndex: 'status',
    key: 'status',
    render: (item: TOrder) => <Text>{STATUS_MEANING[item.status as IMeaning]}</Text>,
  },
  {
    label: 'Delivery Status:',
    dataIndex: 'delivery_status',
    key: 'delivery_status',
    render: (item: TOrder) => <Text>{STATUS_MEANING[item.delivery_status as IMeaning]}</Text>,
  },
  {
    label: 'Transaction Status:',
    dataIndex: 'Transactions',
    key: 'Transactions',
    render: (item: TOrder) => <Text>{STATUS_MEANING[item.delivery_status as IMeaning]}</Text>,
  },
  {
    label: 'Created:',
    dataIndex: 'createdAt',
    key: 'createdAt',
    render: (item: TOrder) => <DateTime>{item?.createdAt}</DateTime>,
  },
  {
    label: 'Last Update:',
    dataIndex: 'updatedAt',
    key: 'updatedAt',
    render: (item: TOrder) => <DateTime>{item?.updatedAt}</DateTime>,
  },
  {
    label: 'Shipping Address:',
    dataIndex: 'address',
    key: 'address',
    render: (item: TOrder) => (
      <Text>
        {item.region}, {item.state}, {item?.city}, {item?.address}, {item?.suite || ''}{' '}
      </Text>
    ),
  },

  {
    label: 'Price:',
    dataIndex: 'cardPrice',
    key: 'cardPrice',
    render: (item: TOrder) => <Text>{centToDollar(item?.cardPrice)}</Text>,
  },
  {
    label: 'Shipping Price:',
    dataIndex: 'shippingPrice',
    key: 'shippingPrice',
    render: (item: TOrder) => <Text>{centToDollar(item?.shippingPrice)}</Text>,
  },
  {
    label: 'Tax:',
    dataIndex: 'tax',
    key: 'tax',
    render: (item: TOrder) => <Text>{centToDollar(item?.tax)}</Text>,
  },
];

const descriptionsHighlight: Array<TDescription<TOrder>> = [
  {
    label: 'Highlight ID:',
    dataIndex: 'Highlight',
    key: 'highlight-id',
    render: (item: TOrder) => <Text>#{item?.Highlight?.objectId?.toUpperCase()}</Text>,
  },
  {
    label: 'Created At:',
    dataIndex: 'Highlight',
    key: 'highlight-created',
    render: (item: TOrder) => <DateTime>{item?.Highlight?.createdAt || ''}</DateTime>,
  },
  {
    label: 'Highlight Date:',
    dataIndex: 'Highlight',
    key: 'highlight-date',
    render: (item: TOrder) => <DateTime>{item?.Highlight?.date || ''}</DateTime>,
  },
  {
    label: 'Location:',
    dataIndex: 'Highlight',
    key: 'highlight-location',
    render: (item: TOrder) => <Text>{item?.Highlight?.location}</Text>,
  },
  {
    label: 'Description:',
    dataIndex: 'Highlight',
    key: 'highlight-description',
    render: (item: TOrder) => <Text>{item?.Highlight?.description}</Text>,
  },
  {
    label: 'Video Preview:',
    dataIndex: 'Highlight',
    key: 'highlight-description',
    render: (item: TOrder) => (
      <Text>
        <a href={item?.Highlight?.Video?.file?.url}>(download)</a>
      </Text>
    ),
  },
];
