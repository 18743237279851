import React from 'react';
import {Popconfirm} from 'antd';
import {DeleteOutlined} from '@ant-design/icons';
import {DefaultButton} from './Default';

interface Props {
  onClick: () => void;
}

export const DeleteButton: React.FC<Props> = ({onClick}) => {
  return (
    <Popconfirm title="Are you sure delete?" onConfirm={onClick} okText="Yes" cancelText="No">
      <DefaultButton type="primary" danger icon={<DeleteOutlined />}>
        Delete
      </DefaultButton>
    </Popconfirm>
  );
};
