import {useQuery} from '@apollo/react-hooks';
import {message} from 'shared/ui-kit/antd';
import {GetMoreOrders} from '../api';
import {TItemData, TOrder} from 'shared/types/order';
import {LIMIT_ELEMENTS_PAGINATION} from 'shared/consts/screen';
import {getErrorObject} from 'shared/lib/helpers/error';

export const useGetOrderList = () => {
  const {
    data,
    loading,
    fetchMore,
    error: errorGetOrder,
  } = useQuery(GetMoreOrders, {
    variables: {first: LIMIT_ELEMENTS_PAGINATION, order: 'createdAt_DESC'},
  });

  const fetchData = async () => {
    try {
      await fetchMore({
        query: GetMoreOrders,
        variables: {
          cursor: data?.orders?.edges.length > 0 ? data?.orders?.edges[data.orders.edges.length - 1].cursor : '',
          first: LIMIT_ELEMENTS_PAGINATION,
          order: 'createdAt_DESC',
        },
        updateQuery: (previousResult, {fetchMoreResult}) => {
          const previousOrders = previousResult.orders.edges;
          const newOrders = fetchMoreResult.orders.edges;
          return {
            orders: {
              edges: [...previousOrders, ...newOrders],
              count: fetchMoreResult.orders.count,
              __typename: previousResult.orders.__typename,
            },
          };
        },
      });
    } catch (e) {
      const error = getErrorObject(e);
      message.error(error.message);
    }
  };

  errorGetOrder && message.error(errorGetOrder.message);

  const total = data?.orders?.count || 0;
  const items = data?.orders?.edges.map((item: TItemData<TOrder>) => ({...item.node})) || [];
  return {
    items,
    total,
    loading,
    fetchData,
  };
};
