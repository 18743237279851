import React from 'react';
import {DeleteButton} from 'shared/ui-kit/Button/Delete';

type DeleteUserPropsT = {
  onDelete: () => void;
};

export const DeleteUser = ({onDelete}: DeleteUserPropsT) => {
  return <DeleteButton onClick={onDelete} />;
};
