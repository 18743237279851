import {Route} from '../lib/helpers/route';

export const routes = {
  all: Route.of({path: '*'}),
  main: Route.of({path: '/'}),
  auth: Route.of({path: '/auth'}),

  users: Route.of({path: '/users'}),
  usersCreate: Route.of({path: '/users/create'}),
  usersView: Route.of<{id: string}>({path: '/users/:id'}),
  usersEdit: Route.of<{id: string}>({path: '/users/:id/edit'}),

  orders: Route.of({path: '/orders'}),
  ordersView: Route.of<{id: string}>({path: '/orders/:id'}),
  ordersEdit: Route.of<{id: string}>({path: '/orders/:id/edit'}),

  roles: Route.of({path: '/roles'}),
  sessions: Route.of({path: '/sessions'}),
};
