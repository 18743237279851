import React, {ReactNode, forwardRef} from 'react';
import {Link} from 'react-router-dom';
import styled from 'styled-components';
import {ButtonShape} from 'antd/es/button';
import {SizeType} from 'antd/lib/config-provider/SizeContext';
import {ButtonType} from 'antd/lib/button/button';
import {Button} from 'antd';

interface Props {
  icon?: ReactNode;
  shape?: ButtonShape;
  to?: string;
  size?: SizeType;
  type?: ButtonType;
  danger?: boolean;
  children?: ReactNode;
  onClick?: () => void;
  ref?: any;
}
export const DefaultButton: React.FC<Props> = forwardRef(({to = '', children, ...rest}, ref) => {
  return to ? (
    <Link to={to}>
      <Default {...rest}>{children}</Default>
    </Link>
  ) : (
    <Default ref={ref} {...rest}>
      {children}
    </Default>
  );
});

DefaultButton.displayName = 'DefaultButton';

const Default = styled(Button)`
  @media all and (max-width: 769px) {
    span:not(.anticon) {
      display: none;
    }
  }
`;
