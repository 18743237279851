import {gql} from '@apollo/client';

export const Current = gql`
  query viewer {
    viewer {
      sessionToken
      user {
        id
        objectId
        username
        email
      }
    }
  }
`;
